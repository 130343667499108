import {
  css,
} from 'styled-components';
import theming from 'styled-theming';
import palette from './palette';
import {
  getResponsiveBreakpoint,
} from './cssHelpers';

export const fontSize = 16;

// 1.5715 such exact magic number is to be consistent with Ant Design v.4
// which use same line height across its components
const lineHeightBase = 1.5;
export const ratio = lineHeightBase;

const hue = 210;
const hueAccent = 30;

const primaryBase = `${hue}, 75%, 45%`;
const warningBase = '40, 96%, 53%';
const darkBase = `${hue}, 0%, 0%`;
const lightBase = `${hue}, 50%, 100%`;
const backgroundBase = `${hue}, 42%, 94%`;

// Background

// export const onBackgroundColorTitle = theming('mode', {
//   light: 'rgba(0, 0, 0, 0.75)',
//   dark: 'rgba(255, 255, 255, 0.75)',
// });

// Below

// export const onBelowSurfaceColorTitle = theming('mode', {
//   light: 'rgba(0, 0, 0, 0.6)',
//   dark: 'rgba(255, 255, 255, 0.6)',
// });

// Above

// export const onAboveSurfaceColorTitle = theming('mode', {
//   light: 'rgba(0, 0, 0, 0.85)',
//   dark: 'rgba(255, 255, 255, 1)',
// });

// Major Second Scale

const scaleRatio = 1.15;
export const scale = {
  '-3': scaleRatio ** -3,
  '-2': scaleRatio ** -2,
  '-1': scaleRatio ** -1,
  0: scaleRatio ** 0,
  1: scaleRatio ** 1,
  2: scaleRatio ** 2,
  3: scaleRatio ** 3,
  4: scaleRatio ** 4,
  5: scaleRatio ** 5,
  6: scaleRatio ** 6,
};

// Space 5 Tiers
const space = {};

space['0'] = 0;
space['1'] = ratio * 0.25;
space['2'] = ratio * 0.666;
space['2.5'] = ratio * 0.75;
space['3'] = ratio * 1;
space['3.5'] = ratio * 1.25;
space['4'] = ratio * 1.5;
space['5'] = ratio * 1.75;

// 5 Tiers
const measure = {};
measure['3'] = '60ch';

// Up
measure['4'] = '75ch';
measure['5'] = '90ch';

// Down
measure['2'] = '45ch';
measure['1'] = '30ch';
measure['15'] = '15ch';
measure['0'] = '0ch';

const onPrimary = theming('mode', {
  light: palette.white,
  dark: palette.black,
});

const theme = {
  font: {
    family: `"Roboto", -apple-system, BlinkMacSystemFont, Roboto,
      "Helvetica Neue", Helvetica, Arial, sans-serif`,
    size: {
      xs: `${1 - ratio * 0.25}rem`,
      small: `${1 - ratio * (0.25 / 2)}rem`,
      smaller: `${1 - ratio * (0.25 / 2)}em`,
      base: '1rem',
      larger: `${ratio * 0.75}em`,
      large: `${ratio * 0.75}rem`,
    },
    weight: {
      light: 300,
      normal: 400,
      bold: 600,
    },
  },
  line: {
    height: {
      base: lineHeightBase,
    },
  },
  color: {
    hue,
    error: '#f5222d',
    warningBase,
    light: `hsla(${hue}, 100%, 100%, 1)`,
    primaryBase,
    accent: `hsl(${hueAccent}, 98%, 64%)`,
    text: {
      light: `hsl(${hue}, 49%, 30%)`,
      normal: `hsl(${hue}, 42%, 25%)`,
      rich: `hsl(${hue}, 78%, 33%)`,
      primary: `hsl(${hue}, 100%, 45%)`,
      accent: `hsl(${hueAccent}, 100%, 50%)`,
      success: 'hsl(100, 76%, 28%)',
    },
    darkBase,
    dark: `hsla(${darkBase}, 0.15)`,
    backgroundBase,

    // New
    primary: theming('mode', {
      light: palette.blue[60],
      dark: palette.blue[30],
    }),
    onPrimary,
    primaryHover: theming('mode', {
      light: palette.blue[70],
      dark: palette.blue[40],
    }),
    primaryActive: theming('mode', {
      light: palette.blue[80],
      dark: palette.blue[50],
    }),
    danger: theming('mode', {
      light: palette.red[60],
      dark: palette.red[30],
    }),
    dangerHover: theming('mode', {
      light: palette.red[70],
      dark: palette.red[40],
    }),
    dangerActive: theming('mode', {
      light: palette.red[80],
      dark: palette.red[50],
    }),
    onDanger: theming('mode', {
      light: palette.black,
      dark: palette.gray[90],
    }),
    background: theming('mode', {
      light: palette.gray[10],
      dark: palette.gray[90],
    }),
    onBackground: theming('mode', {
      light: palette.gray[70],
      dark: palette.gray[30],
    }),
    surface: theming('mode', {
      light: palette.white,
      dark: palette.gray[80],
    }),
    onSurface: theming('mode', {
      light: palette.black,
      dark: palette.gray[10],
    }),
    surfaceInverted: theming('mode', {
      light: '#48484A',
      dark: `hsl(${lightBase})`,
    }),
    onSurfaceInverted: theming('mode', {
      light: `hsla(${lightBase}, 0.9)`,
      dark: `hsla(${darkBase}, 0.9)`,
    }),
    underlay: theming('mode', {
      light: palette.gray[20],
      dark: palette.gray[70],
    }),
    onUnderlay: theming('mode', {
      light: palette.gray[80],
      dark: palette.gray[20],
    }),
    border: theming('mode', {
      light: palette.blue[20],
      dark: palette.gray[70],
    }),
    neutral: theming('mode', {
      light: palette.gray[20],
      dark: palette.black,
    }),
    onNeutral: theming('mode', {
      light: palette.gray[70],
      dark: palette.gray[20],
    }),
    success: theming('mode', {
      light: palette.green[50],
      dark: palette.green[40],
    }),
    onSuccess: theming('mode', {
      light: palette.gray[90],
      dark: palette.gray[90],
    }),
    warning: theming('mode', {
      light: palette.yellow[50],
      dark: palette.yellow[40],
    }),
    onWarning: theming('mode', {
      light: palette.gray[90],
      dark: palette.gray[90],
    }),
    info: theming('mode', {
      light: palette.blue[30],
      dark: palette.blue[30],
    }),
    onInfo: theming('mode', {
      light: palette.black,
      dark: palette.black,
    }),
    disabled: theming('mode', {
      light: palette.gray[30],
      dark: palette.gray[60],
    }),
    onDisabled: theming('mode', {
      light: palette.gray[50],
      dark: palette.gray[50],
    }),
    focus: theming('mode', {
      light: palette.blue[60],
      dark: palette.white,
    }),
    // TODO: From hover to active we increae lightness but vice versa for primary and other colors
    // Need to make it consistent
    // TODO: Also, it works well when active & border styles are consistent
    hover: theming('mode', {
      light: palette.gray[10],
      dark: palette.gray[70],
    }),
    active: theming('mode', {
      light: palette.gray[20],
      dark: palette.gray[60],
    }),
    // NEW
    // TODO: add primary and secondary text
    placeholder: theming('mode', {
      light: palette.gray[40],
      dark: palette.gray[60],
    }),
    // TODO: Same as onPrimary. merge it
    textInteractive: theming('mode', {
      light: palette.white,
      dark: palette.black,
    }),
  },
  border: {
    // Such interesting border radius choice is to be consistent with Ant Design 4
    // which components we still rely on
    radius: {
      0: '0em',
      1: '0.375em',
      2: '0.5em',
      3: '0.625em',
    },
  },
  boxShadow: {
    none: 'none',
    base: theming('mode', {
      light: '0 1px 8px 0 rgba(20,46,110,0.1)',
      dark: `0 0 0 1px ${palette.gray[70]}`,
    }),
  },
  size: {
    small: '2rem',
    base: '2.5rem',
    large: '3rem',
  },
  screen: {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  breakpoints: {
    mobile: getResponsiveBreakpoint(480),
    tablet: getResponsiveBreakpoint(768),
    laptop: getResponsiveBreakpoint(1200),
    desktop: getResponsiveBreakpoint(1600),
  },
  // Get rid of rhythm
  rhythm: {
    xs: `${lineHeightBase - 1}em`,
    small: `${lineHeightBase - 0.75}em`,
    base: `${lineHeightBase}em`,
    large: `${lineHeightBase + 0.75}em`,
  },
  space: Object.assign(
    {},
    ...Object.keys(space).map(key => ({
      [key]: space[key].toString().concat('rem'),
    })),
  ),
  scale: Object.assign(
    {},
    ...Object.keys(scale).map(key => ({
      [key]: scale[key].toString().concat('rem'),
    })),
  ),
  measure: Object.assign(
    {},
    ...Object.keys(measure).map(key => ({
      [key]: measure[key],
    })),
  ),
  motion: {
    easing: {
      standard: 'cubic-bezier(0.2, 0, 0.38, 0.9)',
      entrance: 'cubic-bezier(0, 0, 0.38, 0.9)',
      exit: 'cubic-bezier(0.2, 0, 1, 0.9)',
      expressive: {
        standard: 'cubic-bezier(0.4, 0.14, 0.3, 1)',
        entrance: 'cubic-bezier(0, 0, 0.3, 1)',
        exit: 'cubic-bezier(0.4, 0.14, 1, 1)',
      },
    },
    duration: {
      moderate: '150ms',
      fast: '70ms',
      fade: '110ms',
      communication: '240ms',
      slow: '400ms',
    },
  },
};

theme.typography = {
  caption1: css`
    font-size: ${theme.scale[-2]};
    line-height: ${theme.space[2.5]};
    font-weight: ${theme.font.weight.normal};
    letter-spacing: 0.32px;
  `,
  body1: css`
    font-size: ${theme.scale[0]};
    line-height: ${theme.space[3]};
    font-weight: ${theme.font.weight.normal};
    /* For Roboto. TODO: Should we have it though? */
    letter-spacing: 0.02rem;
  `,
  body2: css`
    font-size: ${theme.scale[-1]};
    line-height: ${theme.space[2.5]};
    font-weight: ${theme.font.weight.normal};
    letter-spacing: 0.16px;
  `,
  title1: css`
    font-size: ${theme.scale[4]};
    line-height: ${theme.space[4]};
    font-weight: ${theme.font.weight.bold};
  `,
  title2: css`
    font-size: ${theme.scale[3]};
    line-height: ${theme.space[3.5]};
    font-weight: ${theme.font.weight.bold};
  `,
  title3: css`
    font-size: ${theme.scale[2]};
    line-height: ${theme.space[3]};
    font-weight: ${theme.font.weight.bold};
  `,
  title4: css`
    font-size: ${theme.scale[1]};
    line-height: ${theme.space[3]};
    font-weight: ${theme.font.weight.bold};
  `,
  title5: css`
    font-size: ${theme.scale[0]};
    line-height: ${theme.space[3]};
    font-weight: ${theme.font.weight.bold};
  `,
  title6: css`
    font-size: ${theme.scale[-1]};
    line-height: ${theme.space[2.5]};
    font-weight: ${theme.font.weight.bold};
    letter-spacing: 0.16px;
  `,
};

export default theme;
