/* eslint-disable no-unused-vars */
import map from 'lodash/map';
import keys from 'lodash/keys';
import has from 'lodash/has';
import find from 'lodash/find';
import some from 'lodash/some';
import flatten from 'lodash/flatten';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  useQuery,
} from 'react-apollo';
import gql from 'graphql-tag';
import {
  template as compileTemplate,
} from '../common/utils/text';
import {
  MESSAGE_PURPOSE__GREET_PATIENT,
} from '../common/constants';
import useReconcile from '../common/utilsClient/useReconcile';
import {
  getPreferredLanguage,
} from '../common/utils/i18next';
import tracker from '../utils/tracker';
import {
  setLanguagePreference,
  selectLanguagePreference,
} from '../store/preferences';
import {
  selectToken,
} from '../store/token';
import {
  isNotCompleted,
  getStaged,
  STATE_DRAFT,
  deleteDraft,
} from '../store/stage';
import useOnline from '../utils/useOnline';
import useLanguageOptions from '../utils/useLanguageOptions';
import useQuestionnaire, {
  useLatestDraftData,
} from '../utils/useQuestionnaire';

const DataContext = createContext();

const GET_ACTIVITIES = gql`
  query GetActivities {
    my {
      id
      languagePreference
      activities {
        id
        project {
          id
          name
          logoUrl
          templates {
            id
            subject
            content
            purpose
            language
          }
        }
        milestoneName
        clinicianName
        clinicianPhotoUrl
      }
      answersSheets {
        id
        state
        patientId
        activityId
        orderInActivity
        questionnaire {
          id
          name
          minutesToComplete
          translations {
            language
            questionnaireName
          }
        }
      }
    }
  }
`;

const mergeLanguagePreference = (languagePreference, newPreferredLanguages) => {
  const order = {};
  forEach(languagePreference, (value, index) => {
    order[value] = index;
  });
  forEach(newPreferredLanguages, (value, index) => {
    if (!has(order, value)) {
      order[value] = languagePreference.length + index;
    }
  });
  return sortBy(keys(order), value => order[value]);
};

const DataProvider = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const online = useOnline();
  const {
    loading,
    data,
  } = useQuery(GET_ACTIVITIES, {
    fetchPolicy: online
      ? 'cache-and-network' // try to keep this query up to date when online
      : 'cache-first',
  });
  const myLanguagePreference = get(data, 'my.languagePreference');
  const languagePreference = useSelector(selectLanguagePreference);
  const isLoggedIn = !!useSelector(selectToken);
  const allPreferredLanguages = useReconcile(
    useMemo(() => {
      return mergeLanguagePreference(languagePreference, myLanguagePreference);
    }, [
      languagePreference,
      myLanguagePreference,
    ]),
  );
  const activities = get(data, 'my.activities');
  const staged = useSelector(getStaged);
  const myAnswersSheets = get(data, 'my.answersSheets');
  const byActivityId = useMemo(() => {
    return groupBy(sortBy(myAnswersSheets, 'orderInActivity'), 'activityId');
  }, [
    myAnswersSheets,
  ]);
  const currentActivity = useMemo(() => {
    const withNotCompletedAnswersSheet = find(activities, activity => some(byActivityId[activity.id], isNotCompleted(staged)));
    if (withNotCompletedAnswersSheet) {
      return withNotCompletedAnswersSheet;
    }
    return last(activities);
  }, [
    activities,
    byActivityId,
    staged,
  ]);
  const currentAnswersSheet = useMemo(() => {
    return find(
      currentActivity && byActivityId[currentActivity.id],
      isNotCompleted(staged),
    );
  }, [
    staged,
    byActivityId,
    currentActivity,
  ]);
  const currentAnswersSheetId = currentAnswersSheet && currentAnswersSheet.id;
  const projectLanguages = useMemo(() => {
    const templates = get(currentActivity, 'project.templates');
    return map(uniqBy(templates, 'language'), 'language');
  }, [
    currentActivity,
  ]);
  const answersSheets = useMemo(() => {
    return map(
      flatten(values(byActivityId)),
      ({
        id,
        state,
        questionnaire,
      }) => ({
        id,
        state,
        questionnaire,
        draftState: staged[id] && staged[id].state,
      }),
    );
  }, [
    staged,
    byActivityId,
  ]);
  const currentProjectLanguage = getPreferredLanguage(
    allPreferredLanguages,
    projectLanguages,
    projectLanguages[0],
  );
  const minutesToComplete = useMemo(() => {
    const currentTotal = reduce(
      filter(answersSheets, isNotCompleted(staged)),
      (total, {
        questionnaire,
      }) => {
        if (questionnaire && questionnaire.minutesToComplete) {
          return total + questionnaire.minutesToComplete;
        }
        return total;
      },
      0,
    );
    return Math.max(4, currentTotal);
  }, [
    staged,
    answersSheets,
  ]);
  const milestoneName = get(currentActivity, 'milestoneName');
  const template = useMemo(() => {
    const templates = get(currentActivity, 'project.templates');

    return find(templates, {
      language: currentProjectLanguage,
      purpose: MESSAGE_PURPOSE__GREET_PATIENT,
    });
  }, [
    currentProjectLanguage,
    currentActivity,
  ]);
  const welcomeTitle = template && template.subject;
  const welcomeMsg = useMemo(() => {
    const source = template && template.content;
    if (!source) {
      return '';
    }
    return compileTemplate(source)({
      milestoneName,
      minutesToComplete,
    });
  }, [
    template,
    milestoneName,
    minutesToComplete,
  ]);
  const projectLogoUrl = get(currentActivity, 'project.logoUrl');
  const {
    languageOptions,
    languageOptionsLoading,
  } = useLanguageOptions(
    currentAnswersSheetId,
    projectLanguages,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isEqual(languagePreference, allPreferredLanguages)) {
      dispatch(setLanguagePreference(allPreferredLanguages));
    }
  }, [
    dispatch,
    languagePreference,
    allPreferredLanguages,
  ]);
  useEffect(() => {
    forEach(answersSheets, ({
      id,
      state,
    }) => {
      // NOTE: state is remote value take from the server; so if it says "COMPLETED"
      //       it means that the server has confirmed completion
      if (
        state === 'COMPLETED' &&
        staged[id] &&
        staged[id].state === STATE_DRAFT
      ) {
        dispatch(deleteDraft(id));
      }
    });
  }, [
    dispatch,
    staged,
    answersSheets,
  ]);
  useLatestDraftData(currentAnswersSheetId);

  tracker.setUser(get(currentAnswersSheet, 'patientId'));

  const {
    // isFirstInActivity,
    // isStarted,
    // isCompleted,
    // mostRecentDraft,
    // localDraftNeedsUpdate,
    // loadingQuestionnaire,
    translations,
    // currentQuestionId,
  } = useQuestionnaire(currentAnswersSheetId, null, {
    preferRemoteDraftIfNewer: true,
  });

  const availableLanguages = useReconcile(map(languageOptions, 'value'));
  const currentLanguage = getPreferredLanguage(
    languagePreference,
    availableLanguages,
  );

  return (
    <DataContext.Provider
      value={{
        answersSheetId: currentAnswersSheetId,
        languageOptions,
        languageOptionsLoading,
        translations,
        currentLanguage,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;

export const useDataProvider = () => useContext(DataContext);
